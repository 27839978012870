@use '@angular/material' as mat;

/*** ngx loading bar ***/
.ngx-spinner {
  color: $primary !important;
}

.ngx-bar {
  background-color: $primary !important;
}

/* Form required */
.required:after {
  content: " *";
  color: red;
}

/* Custom Style */
.scrollable-table {
  min-height: 150px;
  height: 80vh;
  position: relative;
  overflow: auto;
}

.disabled-link {
  pointer-events: none;
}

/* Rating stars */
.stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: 15px;
  font-family: sans-serif;
  line-height: 1;
}

.stars::before {
  content: "★★★★★";
  letter-spacing: 0px;
  background: linear-gradient(90deg,
      #fc0 var(--percent),
      #eaeaea var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.wide-panel {
  width: 55% !important;
}

.link-underline,
.link-underline-hover:hover {
  text-decoration: underline !important;
  text-decoration-color: var(--bs-primary);
  text-underline-offset: 3px;
}

/************************
Angular Material 
************************/
:root {
  // Paginator
  @include mat.paginator-overrides((container-text-color: inherit,
      container-text-size: 11px,
      container-background-color: inherit,
    ));
  // Radio
  @include mat.radio-overrides((label-text-size: 12px));
  // Button
  @include mat.button-overrides((filled-container-height: 35px,
      outlined-container-height: 35px,
      text-container-height: 35px));
  // Table
  @include mat.table-overrides((background-color: inherit,
      row-item-label-text-font: inherit,
      header-headline-font: inherit,
      footer-supporting-text-font: inherit));
  // Dialog
  @include mat.dialog-overrides((container-max-width: 800px));
  // Expansion
  @include mat.expansion-overrides((container-background-color: inherit,
      container-shape: 3px,
      actions-divider-color: #ddd));
  // Tabs
  @include mat.tabs-overrides((label-text-size: 0.9em));
}

.mat-form-field-fluid {
  display: block !important;
}

.mat-icon-sm {
  height: 17px !important;
  width: 17px !important;
  font-size: 17px !important;
}

.mat-mdc-menu-item {
  display: flex !important;
  align-items: center;
}

.mat-icon {
  display: flex !important;
  align-items: center;
  /* Ensures icon is vertically centered */
}

.mat-mdc-form-field-icon-suffix {
  padding: 0 5px 0 5px !important;
}

.mat-mdc-menu-item-text {
  display: flex !important;
  align-items: center;
  /* Ensures text is vertically centered */
}

.mat-mdc-tooltip-surface {
  min-height: inherit !important;
}

.bottom-sheet-class .mat-bottom-sheet-container.cdk-dialog-container.mat-bottom-sheet-container-large {
  padding: 0px !important
}

.cdk-overlay-container,
.cdk-overlay-pane {
  z-index: 9999 !important;
}

html[data-bs-theme="light"] {

  .success-dialog {
    --mdc-text-button-label-text-color: #FFF !important;
    --mat-snack-bar-button-color: #FFF !important;
    --mdc-snackbar-container-color: var(--bs-primary) !important;
    --mat-snack-bar-button-color: var(--bs-white) !important;
  }

  .danger-dialog {
    --mat-snack-bar-button-color: #FFF !important;
    --mdc-snackbar-container-color: var(--bs-danger) !important;
    --mat-snack-bar-button-color: var(--bs-white) !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
    border-color: var(--bs-gray-400);
  }

}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 0.5em !important;
}

.mat-mdc-form-field-error::before {
  content: "" !important;
  display: inline-block !important;
  width: 12px !important;
  height: 12px !important;
  margin-right: 4px !important;
  margin-top: 3px !important;
  vertical-align: text-bottom !important;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23ff9800' stroke='%23ff9800' stroke-width='0' class='bi bi-exclamation-triangle' viewBox='0 0 16 16'%3E%3Cpath d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z'/%3E%3Cpath d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z'/%3E%3C/svg%3E") no-repeat center center;
}

.mat-mdc-menu-content .mat-mdc-menu-item,
.mat-mdc-select-panel .mat-mdc-option.mdc-list-item,
.mat-mdc-option {
  min-height: 40px;

  .mdc-list-item__primary-text {
    width: 100%;
  }
}

.mat-mdc-optgroup-label .mdc-list-item__primary-text {
  font-weight: 500;
}

html[data-bs-theme="dark"] {

  .mat-mdc-table,
  .mdc-data-table__header-cell,
  .mat-mdc-paginator {
    background: var(--bs-body-bg)
  }
}

/**********************************
Angular Material Overide Variables
**********************************/
html {
  --mat-table-row-item-label-text-size: 12px;
}

/**********************************
Media Queries
**********************************/
@media (max-width: 768px) {
  .wide-panel {
    width: 100% !important;
  }
}